<template>
<div style="background-color: #f0f0f0;padding: 0 0 1.25rem 0">
<!--信息展示-->
<van-row gutter="50">
  <van-col span="7">
    <van-image
    style="margin:0.8rem"
    round
    fit="cover"
    width="6rem"
    height="6rem"
    @click="showAvatar(user.avatar)"
    :src="user.avatar"
    />
  </van-col>
  <van-col span="17">
    <div style="margin-top:1.5rem">
        <div style="font-size:1.05rem;font-weight:bold;">{{user.name}} <span style="font-weight:500;margin-left:1.5rem"><van-tag @click="changeAvatar" plain type="danger">更换头像 ></van-tag></span></div> 
        <div v-if="user.status == 0" style="margin-top:10px"><van-tag type="success" plain size="medium" >正常用户</van-tag></div> 
        <div v-if="user.status == -1" style="margin-top:10px"><van-tag type="warning" plain size="medium" >封禁中</van-tag></div>
        <div style="margin-top:10px;font-size:0.8rem">
           <span style="color:#1296db;font-weight:bold;"><van-icon name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon2.png"  size="1.3rem"/> 积分：{{user.points}}</span> 
           <span style="color:#0e932e;margin-left:1.2rem;font-weight:bold;"><van-icon name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/积分 (1).png" size="1.3rem"/> 减碳量：{{user.reduceCarbon}}kg</span>  
        </div>
    </div>
  </van-col>
</van-row>
<!--账号模块-->
<van-cell-group inset style="margin:0.5rem;">
  <van-cell title="学院" :value="user.school" />
  <van-cell title="专业班级" :value="user.major" />
</van-cell-group>

<!--账号模块-->
<van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem;">
    <template #default>
      <van-grid square :border="false" :column-num="4" icon-size="30px">
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/个人信息.png" @click="toInfo" text="信息认证" />
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/积分 (1).png" @click="toPointsRecords" text="积分明细" />
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/2_11节能环保 (1).png" @click="toCarbonRecords" text="减碳贡献" />
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/活动报表.png" text="参与活动" @click="toActivityJoin"/>
      </van-grid>
    </template>
</van-cell-group>
<!--订单模块-->
<van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem;">
    <template #default>
      <div style="font-size:0.9rem;font-weight:540;margin-left:16px;margin-top:8px">积分兑换</div>
      <van-grid square :border="false" :column-num="4" icon-size="30px">
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/收藏.png" @click="toCollect" text="商品收藏" />
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/收货地址.png" @click="toAddress" text="收货地址" />
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/商品.png" @click="toOrder1" text="已购买" />
        <van-grid-item icon="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/评价.png" @click="toOrder2" text="待评价" />
      </van-grid>
    </template>
</van-cell-group>
<div style="margin:2rem 1rem 1rem 1rem">
    <van-button type="danger" block @click="logout" style="border-radius: 7px">退出登录</van-button>
</div>
</div>
<!--更换头像弹出框-->
<van-dialog v-model:show="show" title="点击上传头像" width="15rem" show-cancel-button @confirm="change" :confirm-button-disabled="isDisable">
  <template #default>
    <van-uploader style="margin:1rem 0 0 3rem" preview-size="9rem" v-model="fileList" multiple :max-count="1" :after-read="uploadImage"/>
  </template>
</van-dialog>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import axios from 'axios';
import { showImagePreview } from 'vant'
import { localRemove,localGet, localSet } from '@/utils'

const store = useStore();
const router = useRouter();
const fileList = ref([]);
const user = ref(store.state.user);
const show = ref(false);
const curAvatar = ref(user.avatar);
const isDisable=ref(false);

onMounted(()=>{
  console.log(localGet('token'));
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
  }
  const url = store.state.baseUrl + "/user/queryInfo"
  const token = localGet('token');
  request("post",url,{},token).then(
    (res)=>{
      if(res.success){
        localSet('user',res.data);
        store.state.user=res.data;
        user.value=res.data;
      }
    }
  )

})

const change=()=>{
  const url = store.state.baseUrl + "/user/changeAvatar"
  const params = {
    avatar: curAvatar.value
  }
  const token = localGet('token');
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
        showSuccessToast("更换成功")
        store.state.isShow = false;
        setTimeout(()=>{
            store.state.isShow = true;
        },20);
      }else{
        showFailToast("更换失败")
        store.state.isShow = false;
        setTimeout(()=>{
            store.state.isShow = true;
        },20);
      }
    }
  )
}

const changeAvatar=()=>{
  show.value=true;
}

const uploadImage=(fileData)=>{
  // 此时可以自行将文件上传至服务器
  isDisable.value=true;
  const baseUrl = store.state.baseUrl;
  const dataV = new FormData(); //重点--使用formdata添加需要一起传的参数
  dataV.append("file", fileData.file);
    axios({
          method: "post",
          headers: { "Content-Type": "multipart/form-data" }, //重点--请求头
          url: baseUrl+"/file/upload",
          data: dataV,
      }).then(function(response){
          console.log(response.data);
          curAvatar.value = response.data.data;
          isDisable.value=false;
      });
}

const toOrder2 = ()=>{
  router.push({
    path: '/order',
    query: {
      name: '1'
    }
  })
}

const toOrder1 = ()=>{
  router.push({
    path: '/order',
    query: {
      name: '0'
    }
  })
}

const toAddress=()=>{
  router.push("/address")
}

const toInfo=()=>{
  router.push("/info")
}

const toCollect=()=>{
  router.push("/collect")
}

/**
 * 跳转到活动详情页
 */
const toActivityJoin=()=>{
  const url = store.state.baseUrl + "/activity/isEnroll"
  const token = localGet('token')
  request("post",url,{},token).then(
    (res)=>{
      if(res.success){
        router.push("/activityJoin");
      }else{
        showFailToast("暂未参加活动")
      }
    }
  )

}

const toPointsRecords=()=>{
  router.push("/pointsRecords");
}

const toCarbonRecords=()=>{
  router.push("/carbonRecords");
}

const showAvatar=(avatar)=>{
  showImagePreview([avatar]);
}

const logout=()=>{
  store.state.user={};
  store.state.isShow = false;
  localRemove('token');
  localRemove('user');
  setTimeout(()=>{
      store.state.isShow = true;
  },20);
  router.push("/login")
}


</script>
<style>
</style>