// 1. 定义路由组件.
// 也可以从其他文件导入
import * as VueRouter from 'vue-router'
import Index from '@/views/index/Index.vue'
import Mine from '@/views/mine/Mine.vue'
import Login from '@/views/login/Login.vue'
import Signup from '@/views/signup/Signup.vue'
import ActivityJoin from '@/views/activityjoin/ActivityJoin.vue'
import PointsRecords from '@/views/pointsrecords/PointsRecords.vue' 
import CarbonRecords from '@/views/carbonrecords/CarbonRecords.vue'
import Info from '@/views/info/Info.vue'
import Shop from '@/views/shop/Shop.vue'
import GoodsDetail from '@/views/goodsdetail/GoodsDetail.vue'
import Collect from '@/views/collect/Collect.vue'
import Address from '@/views/address/Address.vue'
import Order from '@/views/order/Order.vue'
import GoodsComments from '@/views/goodscomments/GoodsComments.vue'
import Blog from '@/views/blog/Blog.vue'
import BlogDetail from '@/views/blogdetail/BlogDetail.vue'
import News from '@/views/news/News.vue'
import NewsDetail from '@/views/newsdetail/NewsDetail.vue'
import CarbonSort from '@/views/carbonsort/CarbonSort.vue'

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  { path: '/', component: Index },
  { path: '/mine', component: Mine },
  { path: '/login', component: Login},
  { path: '/signup', component: Signup},
  { path: '/activityJoin', component:ActivityJoin},
  { path: '/pointsRecords', component:PointsRecords},
  { path: '/carbonRecords', component:CarbonRecords},
  { path: '/info', component:Info},
  { path: '/shop', component:Shop},
  { path: '/goodsDetail', component:GoodsDetail},
  { path: '/collect', component:Collect},
  { path: '/address', component:Address},
  { path: '/order', component:Order},
  { path: '/goodsComments', component:GoodsComments},
  { path: '/blog', component:Blog},
  { path: '/blogDetail', component:BlogDetail},
  { path: '/news', component:News},
  { path: '/newsDetail', component:NewsDetail},
  { path: '/carbonsort', component:CarbonSort}
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = VueRouter.createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: VueRouter.createWebHashHistory(),
  routes, // `routes: routes` 的缩写 
})

export default router