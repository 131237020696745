<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1rem 0"> 
    <div style="font-size:1rem;font-weight:520;margin:0rem 0rem 0rem 1rem">请选择收货地址</div>
    <van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem" v-if="list.length == 0"> 
        <van-empty image-size="10rem" description="暂未填写地址信息" />
    </van-cell-group>
    <van-address-list
    v-model="chosenAddressId"
    :list="list"
    default-tag-text="默认"
    @add="onAdd"
    @edit="onEdit"
    @select="changeAddress"
    />

</div>
<van-divider>没有更多了</van-divider>

<!--添加地址信息弹出层-->
<van-dialog v-model:show="show1" title="添加地址" show-cancel-button @confirm="addAddress" @close="clear">
  <template #default>
    <div style="margin:1rem 0 1rem 0">
        <van-field v-model="place" label="地区" placeholder="请选择地区" right-icon="arrow" @click-input="showBottom" @click-right-icon="showBottom"/>
        <van-field v-model="detail" label="详细地址" placeholder="请输入详细地址" />
    </div>
  </template>
</van-dialog>

<!--地理位置选择弹出层-->
<van-popup
  v-model:show="show2"
  position="bottom"
  :style="{ height: '50%' }"
>
    <template #default>
        <van-area
        title="地区"
        v-model="placeCode"
        :area-list="areaList"
        :columns-placeholder="['请选择', '请选择', '请选择']"
        @confirm="getPlace"
        @cancel="show2=false"
        />
    </template>
</van-popup>

<!--添加地址信息弹出层-->
<van-dialog v-model:show="show3" title="是否该地址删除" show-cancel-button @confirm="deletedAddress" @close="notCancel">
  <template #default>
    <van-collapse  v-model="activeNames">
        <van-collapse-item title="地址" name="1">
              {{ currentAddress }}
        </van-collapse-item>
    </van-collapse>
  </template>
</van-dialog>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { areaList } from '@vant/area-data';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const chosenAddressId = ref(1);
const show1 =ref(false);
const place = ref('');
const detail = ref('');
const show2 =ref(false);
const placeCode=ref('110000');
const show3 =ref(false);
const currentAddress = ref('');
const currentAddressId = ref(0);
const activeNames = ref(['1']);


const list = ref([]);

const init = ()=>{
    const url = store.state.baseUrl + "/address/queryAddress";
    const token =localGet('token');
    request("post",url,{},token).then(
        (res)=>{
            console.log(res)
            if(res.success){
                list.value = res.data;
                const url2 = store.state.baseUrl + "/address/queryCurrentId";
                const token2 =localGet('token');
                request("post",url2,{},token2).then(
                    (res)=>{
                        console.log(res)
                        if(res.success){
                            chosenAddressId.value=res.data;
                        }
                    }
                )
            }
        }
    )
}
init();

/**
 * 删除地址信息
 */
const deletedAddress=()=>{
    const url =store.state.baseUrl+ '/address/deletedAddress';
    const params = {
        addressId: currentAddressId.value
    }
    const token = localGet('token');
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                showSuccessToast("删除成功");
                store.state.isShow = false;
                setTimeout(()=>{
                    store.state.isShow = true;
                },20);
            }else{
                showFailToast("删除失败");
            }
        }
    )



}

/**
 * 添加地址信息
 */
const addAddress=()=>{
    console.log(place.value+detail.value);
    const url = store.state.baseUrl + '/address/addAddress';
    const params = {
        address: place.value + detail.value
    }
    const token = localGet('token');
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                showSuccessToast("添加成功");
                show1.value=false;
                store.state.isShow = false;
                setTimeout(()=>{
                    store.state.isShow = true;
                },20);
            }else{
                showFailToast("添加失败");
                show1.value=false;
            }
        }
    )
    //发起请求
}

/**
 * 将地区码转换为相应的地区
 */
const getPlace=()=>{
    console.log(placeCode);
    const provinceCode = placeCode.value.substring(0,2)+ '0000';
    const province =areaList.province_list[provinceCode];
    const cityCode =placeCode.value.substring(0,4) + '00';
    let city = areaList.city_list[cityCode];
    const countyCode = placeCode.value; 
    let county = areaList.county_list[countyCode]
    place.value = province + city + county;
    show2.value=false;
}

const showBottom=()=>{
    show2.value=true;
}

const clear=()=>{
    place.value='';
    detail.value='';
}

const onAdd = ()=>{
    show1.value=true;
}

const onEdit =(item,index)=>{
    currentAddress.value = item.address;
    currentAddressId.value = item.id;
    show3.value=true;
}

const notCancel=()=>{
    currentAddress.value='';
    currentAddressId.value=0;
    show3.value=false;
}

/**
 * 切换收获地址
 */
const changeAddress = (item,index)=>{
    console.log(item);
    const url = store.state.baseUrl + "/address/changeAddress";
    const params ={
        address: item.address
    }
    const token =localGet('token');
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                showSuccessToast("切换成功");
                history.back();
            }else{
                showFailToast("切换失败");
            }
        }
    )
}
</script>