<template>
    <div  style="background-color: #f0f0f0;padding: 1rem 0 1.25rem 0">
        <div style="font-size:20px;font-weight:bold;margin:0rem 1rem 1rem 8.5rem">信息认证</div>
        <van-cell-group inset>
            <van-field v-model="user.name" label="名字" placeholder="请输入名字(10个字符以内)" />
            <van-field v-model="user.sid" label="学号" placeholder="请输入学号" />
            <van-field v-model="user.school" label="学院" placeholder="请输入学院" />
            <van-field v-model="user.major" label="专业班级" placeholder="请输入专业班级" />
            <van-field v-model="user.password" label="密码" placeholder="密码" />
            <van-field v-model="user.phone" label="手机号" placeholder="请输入手机号" />
        </van-cell-group>
        <div style="margin:2rem 1rem 1rem 1rem">
            <van-button type="primary" block style="border-radius: 7px" @click="update">修改</van-button>
        </div>
    </div>
    <van-divider>没有更多了</van-divider>
</template>
<script setup>
import { ref } from "vue";
import axios from 'axios';
import { request } from '@/api/axiosFun';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const user = ref(store.state.user);

const init=()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
    }
    const url = store.state.baseUrl+"/user/queryDetailInfo"
    const token = localGet('token');
    request("post",url,{},token).then((res)=>{
        if(res.success){
            user.value=res.data;
        }
    })
}
init();

/**
 * 修改信息
 */
const update = ()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
    }
    const url = store.state.baseUrl+"/user/update"
    const params = {
        sid: user.value.sid,
        password: user.value.password,
        name: user.value.name,
        school: user.value.school,
        major: user.value.major,
        phone: user.value.phone,
    }
    const token = localGet('token')
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                showSuccessToast("修改成功");
                store.state.user = res.data;
                router.push("/mine");
                console.log(store.state.user)
            }else{
                showFailToast("修改失败");
            }
        }
    )
}
</script>