<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1rem 0">
    <div style="font-size:1.15rem;font-weight:bold;margin:0rem 0rem 1rem 1rem">我的收藏</div>
    <van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem" v-if="goodsList.length == 0"> 
        <van-empty image-size="10rem" description="暂无收藏" />
    </van-cell-group>
    <van-card
      v-for="(item,index) in goodsList"
      :key="index"
      style="border-radius: 7px;margin-left:0.5rem;margin-right:0.5rem;background-color:white"
    >
      <template #thumb>
        <van-image
          width="7rem"
          height="7.3rem"
          fit="cover"
          :src="item.photo"
          @click="toDetail(item.id)"
        />
      </template>
      <template #title>
        <div style="font-size:13px;font-weight:bold;" @click="toDetail(item.id)">{{ item.name }}</div>
      </template>
      <template #price>
        <div style="font-size:15px;margin-top:0.625rem;color:#d81e06">{{ item.needPoints }}积分</div>
      </template>
      <template #desc>
        <van-tag v-for="(tag,index2) in item.tags" style="margin:0.2rem 0.5rem 0.5rem 0"
        :key="index2" :type="tagTypeList[index2 % tagTypeList.length]">{{tag}}</van-tag>
      </template>
      <template #tags>
        <div style="font-size:0.6875rem;color:#707070">已兑换{{item.salesVolume}}</div>
      </template>
      <template #num>
        <van-button type="primary" size="mini" icon="star-o" @click="cancelCollect(item.id)">取消收藏</van-button>
      </template>
    </van-card>
</div>
<van-divider>没有更多了</van-divider>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const goodsList = ref([]);
const tagTypeList = ["success","danger","primary"]
const init=()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
        return;
    }
    const url = store.state.baseUrl + "/goodsCollect/queryCollect";
    const token = localGet('token');
    request("post",url,{},token).then(
        (res)=>{
            console.log(res)
            if(res.success){
                goodsList.value=res.data;
            }
        }
    )
}
init();

const cancelCollect=(goodsId)=>{
  const url = store.state.baseUrl + "/goodsCollect/collect"
  const params = {
    goodsId: goodsId
  }
  const token = localGet('token')
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
          showSuccessToast(res.data)
          store.state.isShow = false;
          setTimeout(()=>{
            store.state.isShow = true;
          },20);
      }else{
          showFailToast(res.errorMsg)
      }
    }
  )
}

const toDetail=(id)=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  router.push({
    path: '/goodsDetail',
    query: {
        goodsId: id
    }
  })
}
</script>