import {createStore} from 'vuex'  

export default createStore({
  state: {
    token: 'mock',
    user: { },
    baseUrl: 'http://139.196.82.182:8082',  //线上
    // baseUrl: 'http://127.0.0.1:8080',
    isShow: true
  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {

  }
});