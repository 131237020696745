<template>
    <!--标题-->
    <div style="margin:0.5rem 0 0 0.7rem;font-weight:bold;font-size:1.25rem">
        {{ news.title }}
    </div>
    <!--媒体-->
    <div style="margin:1rem 0 0 0.7rem">
        <van-row>
            <van-col span="4">
                <van-image
                round
                width="2.6rem"
                height="2.6rem"
                fit="cover"
                style="margin-left:0.3rem"
                :src="news.avatar"
                />
            </van-col>
            <van-col span="16">
                <van-row style="font-size:0.8rem;margin-top:0.2rem;font-weight:500;font-family:Microsoft YaHei">{{news.medium}}</van-row>
                <van-row style="font-size:0.7rem;margin-top:0.2rem;color:#707070;font-family:Microsoft YaHei">{{news.time}}</van-row>
            </van-col>
        </van-row>
    </div>
    <!--正文-->
    <div v-html="news.content" style="margin:0.5rem 0.7rem 0.5rem 0.7rem;font-size:0.95rem;line-height: 1.5rem;"></div>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter,useRoute } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';

const store = useStore();
const router = useRouter();
const route = useRoute();
const news = ref({});
onMounted(()=>{
    const newsId = route.query.id;
    const url = store.state.baseUrl + "/news/queryNews";
    const params = {
        newsId: newsId
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                news.value = res.data;
            }else{
                showFailToast("无此新闻信息");
                router.push("/news")
            }
        }
    )
})
</script>