<template>
    <!--头像和用户名-->
    <van-row style="margin-top:1rem">
        <van-col span="3" offset="1">
            <van-image
            round
            width="2.7rem"
            height="2.7rem"
            fit="cover"
            @click="showAvatar(blog.userAvatar)"
            :src="blog.userAvatar"
            />
        </van-col>
        <van-col span="8" style="margin-left:0.5rem">
            <van-row style="font-size:0.8rem;font-weight:500;font-family:Microsoft YaHei">{{blog.userName}}</van-row>
            <van-row style="font-size:0.7rem;color:#707070;font-family:Microsoft YaHei;margin-top:0.3rem">{{ blog.publishTime }}</van-row>
        </van-col>
        <van-col span="8"></van-col> <!--待定-->
    </van-row>
    <!--标题-->
    <div class="title">
        {{ blog.title }}
    </div>
    <!--正文-->
    <div class="content">
        {{ blog.content }}
    </div>
    <!--图片展示-->
    <div style="margin: 0.5rem 1rem 0 1rem;">
        <van-image
        v-for="(photo,index) in blog.imagesList"
        :key="index"
        fit="cover"
        radius="5px"
        :src="photo"
        style="margin-top:0.5rem"
        @click="showImage(blog.imagesList,index)"
        />
    </div>
    <div style="witdh:100%;height:5px;background-color:#f0f0f0;margin-top:1rem"></div>

    <!--回复部分-->
    <div v-for="(item,index) in commentsList" :key="index" style="margin:0.5rem 0 0 1rem">
        <!--1级评论-->
        <van-row>
            <van-col span="4">
                <van-image
                round
                width="2.7rem"
                height="2.7rem"
                fit="cover"
                :src="item.userAvatar"
                @click="showAvatar(item.userAvatar)"
                />
            </van-col>
            <van-col span="16">
                <van-row style="font-size:0.8rem;margin-top:0.2rem;font-weight:500;font-family:Microsoft YaHei">{{item.userName}}</van-row>
                <van-row style="font-size:0.7rem;margin-top:0.2rem;color:#707070;font-family:Microsoft YaHei">{{item.publishTime}}</van-row>
            </van-col>
            <van-col span="4" >
                <van-icon @click="likeComment1(index)" :name="item.iconType" size="1.2rem" :color="item.iconColor"/>
                <span style="color:#707070;font-size:0.8rem;float:right;margin:0.22rem 1.6rem 0 0">{{ item.liked }}</span>
            </van-col>
        </van-row>
        <div @click="publishLevel2(item.userName,item.id)" style="margin:0.3rem 1rem 0.2rem 3.5rem;font-size:0.85rem;font-family:Microsoft YaHei;white-space:pre-wrap;">
            {{item.content}}
        </div>
        <!--2级评论-->
        <div v-if="item.secondCommentsVoList!=null" style="background-color:#f0f0f0;margin:0.5rem 1.5rem 0 3.5rem;border-radius:3px;padding-top:0.3rem;padding-bottom:0.3rem">
            <div v-for="(secondComment,index2) in item.secondCommentsVoList" :key="index2" style="margin:0.3rem 0.2rem 0.3rem 0.5rem">
                <span style="color:#1296db;font-size:0.8rem;">
                    {{ secondComment.userName }}:
                </span>
                <span v-if="secondComment.answerUserName !=null" style="color:#1296db;font-size:0.8rem;">
                    <span style="color:black">回复</span>{{" @" + secondComment.answerUserName }}:
                </span>
                <span @click="answerLevel2(secondComment.userName,item.id,secondComment.id)" style="font-size:0.8rem;">
                    {{ secondComment.content }}
                </span>
            </div>
        </div>
        <van-divider></van-divider>
    </div>


    <!--底部评论部分-->
    <van-submit-bar >
        <template #default>
            <van-field @click-input="publishLevel1" style="background-color:#f0f0f0;height:2.2rem;border-radius:20px" v-model="value" placeholder="点击发表评论" />
        </template>
        <template #button>
            <div style="margin:0.2rem 0.5rem 0 1rem">
                <van-icon name="chat-o" size="2rem" />
                <div style="font-size:0.7rem;color:#707070;margin-left:0.6rem">{{ blog.comments }}</div>
            </div>
            <div style="margin:0.2rem 0 0 0.5rem">
                <van-icon :name="blog.iconType" :color="blog.iconColor" @click="likeBlog" size="2rem"/>
                <div style="font-size:0.7rem;color:#707070;margin-left:0.6rem">{{ blog.liked }}</div>
            </div>
        </template>
    </van-submit-bar>

    <!--回复弹出层-->
    <van-popup
    v-model:show="showAnswer"
    @close="clear"
    position="bottom"
    :style="{ height: '18%' }"
    >
        <template #default>
            <div style="margin:1rem">
                <van-row>
                    <van-col span="20">
                        <van-field v-model="answer" type="textarea" :placeholder="placeholder" 
                        style="background-color:#f0f0f0;border-radius:7px"
                        />
                    </van-col>
                    <van-col span="4"> 
                        <van-button @click="publishAnswer" style="margin:3rem 0 0 0.5rem;width:3rem" type="primary" size="small">发表</van-button>
                    </van-col>
                </van-row>
            </div>

        </template>
    </van-popup>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { showImagePreview } from 'vant';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const route = useRoute();
const blog = ref({});
const showAnswer = ref(false);
const answer = ref('');
const placeholder = ref('请输入回复');
const curAnswerType = ref(0);  
const curAnswerCommentId = ref(0);
const curParentCommentId = ref(0);
const commentsList = ref([]);



onMounted(()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
    }
    const blogId = route.query.blogId;
    const url = store.state.baseUrl + "/blog/queryBlogById"
    const params = {
        blogId: blogId
    }
    const token = localGet('token');
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                blog.value=res.data;
            }
        }
    )
    const url2 = store.state.baseUrl + "/blogComments/queryBlogComments"
    request("post",url2,params,token).then(
        (res)=>{
            if(res.success){
                commentsList.value = res.data;
                console.log(res.data)
            }
        }
    )
})

/**
 * 点赞1级评论
 */
const likeComment1=(index)=>{
    // console.log(index);
    const url = store.state.baseUrl + "/blogComments/likeComment"
    const token = localGet('token');
    if(commentsList.value[index].iconType == "good-job-o"){
        commentsList.value[index].iconType = "good-job"
        commentsList.value[index].iconColor = "#d81e06"
        commentsList.value[index].liked +=1;
        //点赞++
        const params = {
            commentId: commentsList.value[index].id,
            type: 0 
        }
        // console.log(params);
        request("post",url,params,token).then()
    }else{
        commentsList.value[index].iconType = "good-job-o"
        commentsList.value[index].iconColor = ""
        commentsList.value[index].liked -=1;
        //点赞--
        const params = {
            commentId: commentsList.value[index].id,
            type: 1 
        }
        request("post",url,params,token).then()
    }    

}


/**
 * 发表回复
 */
const publishAnswer=()=>{
    if(curAnswerType.value==0){
        //发表1级评论
        const url = store.state.baseUrl + "/blogComments/publishLevel1";
        const params = {
            blogId: blog.value.id,
            content: answer.value
        }
        const token = localGet('token');
        request("post",url,params,token).then(
            (res)=>{
                if(res.success){
                    showSuccessToast("发表成功");
                    store.state.isShow = false;
                    setTimeout(()=>{
                        store.state.isShow = true;
                    },20);
                }
            }
        )

    }else if(curAnswerType.value==1){
        //在1级评论下发表2级评论
        const url = store.state.baseUrl + "/blogComments/publishLevel2";
        const params = {
            blogId: blog.value.id,
            content: answer.value,
            parentId: curParentCommentId.value,
            answerId: curAnswerCommentId.value
        }
        const token = localGet('token');
        request("post",url,params,token).then(
            (res)=>{
                if(res.success){
                    showSuccessToast("回复成功");
                    store.state.isShow = false;
                    setTimeout(()=>{
                        store.state.isShow = true;
                    },20);
                }
            }
        )

    }else if(curAnswerType.value==2){
        //在在1级评论下回复2级评论
        const url = store.state.baseUrl + "/blogComments/answerLevel2"
        const params = {
            blogId: blog.value.id,
            content: answer.value,
            parentId: curParentCommentId.value,
            answerId: curAnswerCommentId.value
        }
        const token = localGet('token');
        request("post",url,params,token).then(
            (res)=>{
                if(res.success){
                    showSuccessToast("回复成功");
                    store.state.isShow = false;
                    setTimeout(()=>{
                        store.state.isShow = true;
                    },20);
                }
            }
        )
    }
}

/**
 * 点击下方发布1级评论
 */
const publishLevel1 = ()=>{
    showAnswer.value=true;
    curAnswerType.value=0;
    placeholder.value='请输入回复';
}
/**
 * 在1级评论下发布2级评论(非回复)
 */
const publishLevel2 =(userName,commentId)=>{
    showAnswer.value=true;
    curAnswerType.value=1;
    placeholder.value="回复 @"+userName+"：";
    curAnswerCommentId.value = commentId;
    curParentCommentId.value = commentId;
}

/**
 * 在1级评论下回复2级评论(是回复)
 */
const answerLevel2 =(userName,parentId,answerId)=>{
    showAnswer.value=true;
    curAnswerType.value=2;
    placeholder.value="回复 @"+userName+"：";
    curAnswerCommentId.value = answerId;
    curParentCommentId.value = parentId;
}


const clear = ()=>{
    placeholder.value='请输入回复';
    curAnswerCommentId.value = 0;
    curParentCommentId.value = 0;
    answer.value='';
}

/**
 * 点赞博客
 */
const likeBlog=()=>{
    const url = store.state.baseUrl +"/blog/liked";
    const token = localGet('token');
    if(blog.value.iconType == "good-job-o"){
        blog.value.iconType = "good-job"
        blog.value.iconColor = "#d81e06"
        blog.value.liked +=1;
        //点赞++
        const params = {
            blogId: blog.value.id,
            type: 0 
        }
        request("post",url,params,token).then()
    }else{
        blog.value.iconType = "good-job-o"
        blog.value.iconColor = ""
        blog.value.liked -=1;
        //点赞--
        const params = {
            blogId: blog.value.id,
            type: 1 
        }
        request("post",url,params,token).then()
    }    
}

const showAvatar=(avatar)=>{
  showImagePreview([avatar]);
}

const showImage=(imagesList,index)=>{
    showImagePreview({
        images: imagesList,
        startPosition: index
    });
}




        

</script>
<style>
.title{
    font-size: 1rem;
    font-family:Microsoft YaHei;
    font-weight:bold;
    margin: 0.5rem 1rem 0 1rem;
    white-space:pre-wrap;
}

.content{
    font-size: 0.85rem;
    font-family:Microsoft YaHei;
    margin: 1rem 1rem 0 1rem;
    white-space:pre-wrap;
    line-height: 1.5rem;
}


</style>