<template>
    <van-nav-bar
    title="碳普平台"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
  />
  <router-view v-if="store.state.isShow"></router-view>
  <div style="margin-bottom:18%"></div>
  <van-tabbar v-model="active" v-if="route.path != '/goodsDetail'">
    <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
    <van-tabbar-item icon="comment-o" to="/blog">分享</van-tabbar-item>
    <van-tabbar-item>
      <template #icon>
        <van-icon name="add" size="2.5rem" color="#1296db" @click="shareBlog"/>
      </template>
    </van-tabbar-item>
    <van-tabbar-item icon="shopping-cart-o" to="/shop">商城</van-tabbar-item>
    <van-tabbar-item icon="friends-o" to="/mine">我的</van-tabbar-item>
  </van-tabbar>
  <!--发表博客弹出框-->
  <van-dialog v-model:show="isShow" title="发表博客" @confirm="publishBlog" :confirm-button-disabled="isDisable" @close="clearData" show-cancel-button width="30rem">
    <template #default>
      <van-collapse v-model="activeNames">
        <van-collapse-item title="标题" name="1">
          <van-field v-model="title"  placeholder="请输入帖子标题" />
        </van-collapse-item>
        <van-collapse-item title="正文" name="2">
          <van-field v-model="content" autosize rows="3" type="textarea" placeholder="来吧，敬请发挥吧" />
        </van-collapse-item>
        <van-collapse-item title="帖子图片" name="3">
          <van-uploader v-model="fileList" multiple :max-count="5" :after-read="uploadImage"/>
        </van-collapse-item>
     </van-collapse>
      
      <van-divider />
      
    </template>
  </van-dialog>

</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router"
import { ref } from "vue";
import axios from 'axios';
import { onMounted } from 'vue';
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { localGet } from '@/utils'

const route = useRoute();
const router = useRouter();
const store = useStore();
const active = ref(0);
const onClickLeft = () => history.back();
const activeNames = ref(['1','2','3']);
const isShow = ref(false);
const title = ref('');
const content = ref('');
const photos = ref('');
const photoList = ref([]);
const fileList = ref([]);
const isDisable=ref(false);

onMounted(()=>{

})

const publishBlog=()=>{
  let i;
  for(i=0;i<photoList.value.length;i++){
    if(i==0){
      photos.value=photoList.value[i];
    }else{
      photos.value+=","+photoList.value[i];
    }
  }
  const params = {
    title: title.value,
    content: content.value,
    images: photos.value
  }
  const url = store.state.baseUrl + "/blog/publish"
  const token = localGet('token');
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
        showSuccessToast("发布成功");
        router.push("/blog");
        store.state.isShow = false;
        setTimeout(()=>{
            store.state.isShow = true;
        },20);
      }else{
        showFailToast("发布失败");
      }
    }
  )
}

const clearData=()=>{
  title.value='';
  content.value='';
  photos.value='';
  fileList.value=[];
  photoList.value=[];
}

const shareBlog = ()=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  isShow.value=true;
}

const uploadImage=(fileData)=>{
  // 此时可以自行将文件上传至服务器
  isDisable.value=true;
  const baseUrl = store.state.baseUrl;
  const dataV = new FormData(); //重点--使用formdata添加需要一起传的参数
  dataV.append("file", fileData.file);
    axios({
          method: "post",
          headers: { "Content-Type": "multipart/form-data" }, //重点--请求头
          url: baseUrl+"/file/upload",
          data: dataV,
      }).then(function(response){
          console.log(response.data);
          photoList.value.push(response.data.data); 
          isDisable.value=false;
      });
}

</script>

<style>

</style>
