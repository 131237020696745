<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1rem 0">
<van-cell-group inset style="margin:0.5rem" v-for="(item,index) in blogList" :key="index">
    <template #default>
        <div style="margin:0.5rem 0rem 0.5rem 1rem">
            <van-row>
                <van-col span="4">
                    <van-image
                    round
                    width="2.7rem"
                    height="2.7rem"
                    fit="cover"
                    @click="showAvatar(item.userAvatar)"
                    :src="item.userAvatar"
                    />
                </van-col>
                <van-col span="15">
                    <van-row style="font-size:0.9rem;margin-top:0.2rem;font-weight:500;font-family:Microsoft YaHei">{{item.userName}}</van-row>
                    <van-row style="font-size:0.7rem;margin-top:0.2rem;color:#707070;font-family:Microsoft YaHei">{{item.publishTime}}</van-row>
                </van-col>
            </van-row>
            <div @click="toDetail(item.id)" style="margin:0.2rem 0 0.2rem 0.2rem;font-size:0.95rem;font-weight:bold;font-family:Microsoft YaHei;white-space:pre-wrap;">
                {{item.title}}
            </div>
            <div @click="toDetail(item.id)" style="margin:0.7rem 0 0.2rem 0.2rem;font-size:0.82rem;font-family:Microsoft YaHei;white-space:pre-wrap;">
                {{item.content}}
            </div>
            <div style="margin:0.7rem 0 0.2rem 0.2rem;">
                <van-image
                v-for="(photo,index2) in item.imagesList"
                :key="index2"
                fit="cover"
                width="6rem"
                height="6rem"
                radius="5px"
                :src="photo"
                style="margin-right: 0.5rem"
                @click="showImage(item.imagesList,index2)"
                />
            </div>
            <van-row style="margin-top:0.7rem">
                <van-col offset="1" span="7">
                    <van-icon name="share-o" size="1.5rem"/>
                    <span style="font-size:0.85rem;font-family:Microsoft YaHei;float:right;margin:0.2rem 2.5rem 0 0">分享</span>
                </van-col>
                <van-col offset="1" span="7">
                    <van-icon @click="toDetail(item.id)" name="chat-o" size="1.5rem" />
                    <span style="font-size:0.85rem;font-family:Microsoft YaHei;float:right;margin:0.2rem 3.5rem 0 0">{{ item.comments }}</span>
                </van-col>
                <van-col offset="1" span="7">
                    <van-icon @click="like(index)" :name="item.iconType" size="1.5rem" :color="item.iconColor"/>
                    <span style="font-size:0.85rem;font-family:Microsoft YaHei;float:right;margin:0.2rem 3.5rem 0 0">{{ item.liked }}</span>
                </van-col>
            </van-row>
        </div>
    </template>
</van-cell-group>
</div>
<div style="margin-top:0.5rem">
    <van-pagination v-model="current" :total-items="total" :items-per-page="size" @change="pageChange"/>
</div>
<van-divider>没有更多了</van-divider>
</template>

<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { showImagePreview } from 'vant';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const blogList =ref([]);
const total = ref(0);
const current = ref(1);
const size = ref(5);

onMounted(()=>{
    const url = store.state.baseUrl + "/blog/queryBlog";
    const params={
        current:current.value,
        size:size.value
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                console.log(res.data);
                blogList.value=res.data.records;
                total.value = res.data.total
            }
        }
    )
})

const pageChange=()=>{
    const url = store.state.baseUrl + "/blog/queryBlog";
    const params={
        current:current.value,
        size:size.value
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                blogList.value=res.data.records;
                total.value = res.data.total
            }
        }
    )
}

const toDetail=(id)=>{
    router.push({
        path: "/blogDetail",
        query: {
            blogId: id
        }
    });
}

const like=(index)=>{
    const url = store.state.baseUrl +"/blog/liked";
    const token = localGet('token');
    if(blogList.value[index].iconType == "good-job-o"){
        blogList.value[index].iconType = "good-job"
        blogList.value[index].iconColor = "#d81e06"
        blogList.value[index].liked +=1;
        //点赞++
        const params = {
            blogId: blogList.value[index].id,
            type: 0 
        }
        request("post",url,params,token).then()
    }else{
        blogList.value[index].iconType = "good-job-o"
        blogList.value[index].iconColor = ""
        blogList.value[index].liked -=1;
        //点赞--
        const params = {
            blogId: blogList.value[index].id,
            type: 1 
        }
        request("post",url,params,token).then()
    }    
}

const showAvatar=(avatar)=>{
  showImagePreview([avatar]);
}

const showImage =(imagesList,index)=>{
    showImagePreview({
        images: imagesList,
        startPosition: index
    });
}
</script>