<template>
    <div v-for="(item,index) in newsList" :key="index" >
    <van-row @click="toNewsDetail(item.id)">
        <van-col span="8" v-if="item.cover!=null">
            <van-image
            width="7.2rem"
            height="4.6rem"
            fit="cover"
            position="left"
            style="margin:0.5rem"
            :src="item.cover"
            />
        </van-col>
        <van-col span="15" offset="1" v-if="item.cover!=null">
            <van-row><div style="margin:0.5rem 0.2rem 0 0;font-size:1rem">{{item.title}}</div></van-row>
            <van-row v-if="item.cover!=null" style="margin-top:2.1rem">
                <div style="color:#707070;white-space:pre">{{item.medium+"   "}}{{item.time}}</div>
            </van-row>
        </van-col>
        <van-col span="23" offset="1" v-if="item.cover==null">
            <van-row><div style="margin:0.5rem 0.2rem 0 0;font-size:1rem">{{item.title}}</div></van-row>
            <van-row v-if="item.cover==null" style="margin-top:1.2rem">
                <div style="color:#707070;white-space:pre">{{item.medium+"   "}}{{item.time}}</div>
            </van-row>
        </van-col>
    </van-row>
    <hr style="border:1 dashed #987cb9" color="#f0f0f0" size="0.3px">
    </div>
    <div>
        <van-pagination v-model="current" :total-items="total" :items-per-page="size" @change="pageChange"/>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import axios from 'axios';

const store = useStore();
const router = useRouter();
const newsList = ref([]);
const total = ref(0);
const current = ref(1);
const size = ref(8)


onMounted(()=>{
    const url = store.state.baseUrl + "/news/queryByPage";
    const params={
        current:current.value,
        size:size.value
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                console.log(res.data);
                newsList.value = res.data.records;
                total.value = res.data.total
            }
        }
    )
})

const pageChange=()=>{
    const url = store.state.baseUrl + "/news/queryByPage";
    const params={
        current:current.value,
        size:size.value
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                console.log(res.data);
                newsList.value = res.data.records;
                total.value = res.data.total
            }
        }
    )
}

const toNewsDetail=(newsId)=>{
    router.push({
        path: "/newsDetail",
        query:{
            id: newsId
        }
    })
}
</script>
<style>
:root:root{
  --van-pagination-item-default-color: #25bd5d;
}
</style>