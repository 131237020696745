<template>
<div  style="background-color: #f0f0f0;padding: 1rem 0 1.25rem 0">
    <div style="font-size:20px;font-weight:bold;margin:0rem 1rem 1rem 8rem">用户注册</div>
    <van-cell-group inset>
        <van-field v-model="name" label="名字" placeholder="请输入名字" />
        <van-field v-model="sid" label="学号" placeholder="请输入学号" />
        <van-field v-model="school" label="学院" placeholder="请输入学院" />
        <van-field v-model="major" label="专业班级" placeholder="请输入专业班级" />
        <van-field v-model="password" label="密码" placeholder="密码" />
        <van-field v-model="phone" label="手机号" placeholder="请输入手机号" />
    </van-cell-group>
    <div style="margin:1rem 0 0rem 1rem">
        <div style="font-size:15px;font-weight:500;margin:0 0 1rem 1rem">头像上传</div>
        <van-uploader v-model="fileList" :max-count="1" :after-read="uploadImage" preview-size="130px"/>
    </div>
    <div style="margin:2rem 1rem 1rem 1rem">
        <van-button type="primary" block style="border-radius: 7px" @click="signup" :disabled="isDisable">注册</van-button>
    </div>
</div>
</template>
<script setup>
import { ref } from "vue";
import axios from 'axios';
import { request } from '@/api/axiosFun';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';

const store = useStore();
const router = useRouter();
const fileList = ref([]);

const sid=ref('');
const password=ref('');
const name=ref('');
const school=ref('');
const major=ref('');
const avatar=ref('');
const phone=ref('');
const isDisable=ref(false);
/**
 * 图片上传  
 */
const uploadImage=(fileData)=>{
  // 此时可以自行将文件上传至服务器
  const baseUrl = store.state.baseUrl;
  const dataV = new FormData(); //重点--使用formdata添加需要一起传的参数
  isDisable.value=true;
  dataV.append("file", fileData.file);
    axios({
            method: "post",
            headers: { "Content-Type": "multipart/form-data" }, //重点--请求头
            url: baseUrl+"/file/upload",
            data: dataV,
        }).then(function(response){
            console.log(response.data);
            avatar.value = response.data.data;
            isDisable.value=false;
        });
}

/**
 * 注册
 */
const signup = ()=>{
    if(sid.value==''||password.value==''||name.value==''||school.value==''||major.value==''||phone.value==''){
        showFailToast("参数不能为空");
        return;
    }
    const url = store.state.baseUrl+"/user/signup"
    const params = { 
        sid: sid.value,
        password: password.value,
        name: name.value,
        school: school.value,
        major: major.value,
        avatar: avatar.value,
        phone: phone.value
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                showSuccessToast("注册成功");
                router.push("/login");
            }else{
                showFailToast("注册失败");
            }
        }
    )
}
</script>