<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1.25rem 0">
    <div style="font-size:1.15rem;font-weight:bold;color:#0e932e;margin:0rem 0rem 1rem 1rem">积分明细</div>
    <van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem" v-if="recordsList==null"> 
        <van-empty image-size="10rem" description="暂无记录" />
    </van-cell-group>
    <van-cell-group inset v-for="(item,index) in recordsList" :key="index" style="margin:1rem 0.5rem 0.5rem 0.5rem">
        <van-cell size="large" title-style="font-size:1rem;font-weight:bold;" center>
            <template #title>
                <div >{{ item.method }}</div>
            </template>
            <template #label>
                <div >{{ item.createTime }}</div>
            </template>
            <template #value>
                <div v-if="item.number>0" style="font-size:1.2rem;font-weight:bold;color:#0e932e"> +{{ item.number }}</div>
                <div v-if="item.number<0" style="font-size:1.2rem;font-weight:bold;color:#d81e06"> {{ item.number }}</div>
            </template>
        </van-cell>
    </van-cell-group>
</div>
<van-divider>没有更多了</van-divider>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { request } from '@/api/axiosFun';
import { showSuccessToast, showFailToast } from 'vant';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();

const recordsList = ref();
const init = ()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
        return;
    }
    const url = store.state.baseUrl + "/pointsRecords/queryPointsRecords";
    const token = localGet('token');
    request("post",url,{},token).then(
        (res)=>{
            console.log(res)
            recordsList.value=res.data;
        }
    )
}
init();
</script>
<style>

</style>