<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1rem 0">
<van-cell-group inset style="margin:0.5rem">
    <template #default>
        <div style="margin:0.5rem 0rem 0.5rem 1rem" v-for="(item,index) in commentList" :key="index" >
            <van-row>
                <van-col span="4">
                    <van-image
                    round
                    width="2.5rem"
                    height="2.5rem"
                    fit="cover"
                    :src="item.userAvatar"
                    />
                </van-col>
                <van-col span="15">
                    <van-row style="font-size:0.8rem">{{item.userName}}</van-row>
                    <van-row><van-rate v-model="item.stars" readonly allow-half size="1rem" style="margin-top:0.5rem"/></van-row>
                </van-col>
                <van-col span="5" style="margin-top:0.3rem">{{item.commentTime}}</van-col>
            </van-row>
            <div style="margin:0.7rem 0 0.2rem 0.2rem;font-size:0.8rem">
                {{item.comments}}
            </div>
            <div style="margin:0.7rem 0 0.2rem 0.2rem;">
                <van-image
                v-for="(photo,index2) in item.photoList"
                :key="index2"
                width="6rem"
                height="6rem"
                radius="5px"
                fit="cover"
                :src="photo"
                style="margin-right: 0.5rem"
                @click="showImages(item.photoList,index2)"
                />
            </div>
            <van-divider />
        </div>
    </template>
</van-cell-group>
<van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem" v-if="commentList.length == 0"> 
    <van-empty image-size="10rem" description="暂无记录" />
</van-cell-group>
</div>
<van-divider>没有更多了</van-divider>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { showImagePreview } from 'vant';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const route = useRoute();
const goodsId = ref(0);
const commentList = ref([]);
onMounted(()=>{
    goodsId.value = route.query.goodsId;
    const url = store.state.baseUrl + "/goodsComments/queryGoodsComments"
    const params = {
        goodsId: goodsId.value
    }
    const token = localGet('token');
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                commentList.value=res.data;
            }
        }
    )
})

const showImages=(imagesList,index)=>{
    showImagePreview({
        images: imagesList,
        startPosition: index
    });
}
</script>