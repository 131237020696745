import { createApp } from 'vue'
import App from './App.vue'
import store from './vuex/store'
import router from './router/router'
import Vant from 'vant'
import 'vant/lib/index.css'
import "lib-flexible/flexible.js";

const app=createApp(App)

app.use(router).use(Vant).use(store)

app.mount('#app')
