<template>
<div style="background-color: #f0f0f0;padding: 0 0 1.25rem 0">
    <div style="margin:0 2rem 2rem 3rem">
        <van-image
        style="margin-top:2rem"
        round
        width="16rem"
        height="16rem"
        fit="cover"
        src="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/E-1136250-0E6B4A25.jpg"
        />
    </div>
    <div style="font-size:20px;font-weight:bold;color:#0e932e;margin:1rem 1rem 2rem 1.8rem">"碳达峰、碳中和"科普行动管理平台</div>
    <van-cell-group inset>
        <van-field v-model="account" label="账号" placeholder="请输入学号或手机号" />
        <van-field v-model="password" label="密码" type="password" placeholder="请输入密码" />
    </van-cell-group>
    <van-row style="margin:2rem 1rem 2rem 5.2rem" gutter="50">
        <van-col span="8"><van-button type="success" @click="signup">注册</van-button></van-col>
        <van-col span="8"><van-button type="primary" @click="login">登录</van-button></van-col>
    </van-row>

</div>
</template>

<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { request } from '@/api/axiosFun';
import { useStore } from 'vuex';
import { showSuccessToast, showFailToast } from 'vant';
import { useRouter } from "vue-router";
import { localSet } from '@/utils'

const store = useStore();
const router = useRouter();
const account =ref('');
const password=ref('');

const login=()=>{
    const url = store.state.baseUrl + "/user/login";
    const params = {
        account: account.value,
        password: password.value
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                //登录成功
                showSuccessToast("登录成功");
                store.state.user=res.data.user;
                localSet('token',res.data.token);
                console.log(store.state);
                router.push("/mine");
            }else{
                showFailToast('登录失败，账号或密码错误');
            }
        }
    )
}

const signup=()=>{
    router.push("/signup");
}

</script>
<style>
</style>