<template>
<div style="background-color: #f0f0f0;padding: 0 0 1rem 0">
    <!--标签栏-->
    <van-tabs v-model:active="activeName" @change="changeTab" color="#d81e06" background="#f0f0f0" style="margin:0 0.5rem 0.8rem 0.5rem;">
        <van-tab title="待收货" name="0"></van-tab>
        <van-tab title="待评价" name="1"></van-tab>
        <van-tab title="已评价" name="2"></van-tab>
    </van-tabs>
    <!--订单信息展示-->
    <van-card
      v-for="(item,index) in orderList"
      :key="index"                          
      style="border-radius: 7px;margin-left:0.5rem;margin-right:0.5rem;background-color:white"
    >
      <template #thumb>
        <van-image
          width="7rem"
          height="7.3rem"
          fit="cover"
          :src="item.goodsPhoto"
          @click="toDetail(item.goodsId)"
        />
      </template>
      <template #title>
        <div style="font-size:13px;font-weight:bold;" @click="toDetail(item.goodsId)">{{ item.goodsName }}</div>
      </template>
      <template #desc>
        <van-tag v-for="(tag,index2) in item.goodsTags" style="margin:0.2rem 0.5rem 0.5rem 0"
        :key="index2" :type="tagTypeList[index2 % tagTypeList.length]">{{tag}}</van-tag>
      </template>
      <template #price>
        <div style="font-size:15px;font-weight:bold;color:#d81e06">{{ item.goodsPoints }}积分</div>
        <div style="font-size:13px;color:#8a8a8a">×{{ item.number }}</div>
      </template>
      <template #num>
        <div style="font-size:13px;color:black;font-weight:bold;margin-top:1rem;font-family:Microsoft YaHei">总花费 <span style="font-size:1rem;color:#0e932e">{{ item.costPoints }}</span> 积分</div>
      </template>
      <template #footer>
        <span style="margin-right:4.2rem;font-size:0.5rem;color:#8a8a8a">订单编号: {{ item.id }}</span>
        <van-button v-if="item.status==0" @click="confirmDelivery(item.id)" plain type="primary" size="small" style="margin-top:0.3rem">确认收货</van-button>
        <van-button v-if="item.status==1" @click="comment(item.id,item.goodsId)" plain type="success" size="small" style="margin-top:0.3rem" >商品评价</van-button>
        <van-button v-if="item.status==2" @click="comment(item.id,item.goodsId)" plain type="primary" size="small" style="margin-top:0.3rem" >追加评价</van-button>

      </template>
    </van-card>
    <van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem" v-if="orderList.length == 0"> 
        <van-empty image-size="10rem" description="暂无记录" />
    </van-cell-group>
</div>

<!--商品评价弹出层-->
<van-dialog v-model:show="show" title="商品评价" @close="clear" @confirm="submitComment" :confirm-button-disabled="isDisable" show-cancel-button confirm-button-color="#0e932e">
    <template #default>
        <van-collapse v-model="actives">
            <van-collapse-item title="商品评分" name="1">
                <van-rate v-model="currentStars" allow-half size="1.5rem"/>
            </van-collapse-item>
            <van-collapse-item title="商品评价" name="2">
                <van-field v-model="currentComments" type="textarea" placeholder="请输入商品评价" :autosize="true" clearable/>
            </van-collapse-item>
            <van-collapse-item title="照片上传" name="3">
                <van-uploader v-model="fileList" multiple :max-count="5" :after-read="uploadImage"/>
            </van-collapse-item>
        </van-collapse>
    </template>
</van-dialog>


</template>

<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import axios from 'axios';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const route = useRoute();
const activeName = ref('2');
const tagTypeList = ["success","danger","primary"]
const orderList = ref([])
const show = ref(false);
const actives = ref(['1','2','3']);
const currentStars = ref(0);
const currentGooodsId = ref(0);
const currentOrderId = ref('');
const currentComments = ref('');
const photos = ref('');
const photoList = ref([]);
const fileList = ref([]);
const isDisable=ref(false);

const submitComment=()=>{
  let i;
  for(i=0;i<photoList.value.length;i++){
    if(i==0){
      photos.value=photoList.value[i];
    }else{
      photos.value+=","+photoList.value[i];
    }
  }
  const params={
    orderId: currentOrderId.value,
    goodsId: currentGooodsId.value,
    comments: currentComments.value,
    photos: photos.value,
    stars: currentStars.value
  }
  console.log(params);
  const url = store.state.baseUrl + "/order/comment";
  const token = localGet('token');
  request("post",url,params,token).then(
    (res)=>{
        if(res.success){
            showSuccessToast("评论成功");
            store.state.isShow = false;
            setTimeout(()=>{
                store.state.isShow = true;
            },20);
        }else{
            showFailToast("评论失败");
        }
    }
  )

}

const uploadImage=(fileData)=>{
  // 此时可以自行将文件上传至服务器
  isDisable.value=true;
  const baseUrl = store.state.baseUrl;
  const dataV = new FormData(); //重点--使用formdata添加需要一起传的参数
  dataV.append("file", fileData.file);
    axios({
            method: "post",
            headers: { "Content-Type": "multipart/form-data" }, //重点--请求头
            url: baseUrl+"/file/upload",
            data: dataV,
        }).then(function(response){
            console.log(response.data);
            photoList.value.push(response.data.data);
            isDisable.value=false;
        });
}

/**
 * 商品评价
 */
const comment=(id,goodsId)=>{
    show.value=true;
    currentOrderId.value=id;
    currentGooodsId.value=goodsId;
}
/**
 * 关闭弹出层触发
 */
const clear=()=>{
    currentStars.value=0;
    currentComments.value='';
    currentGooodsId.value=0;
    currentOrderId.value='';
    photos.value='';
    photoList.value=[];
    fileList.value=[];
}

/**
 * 确认收货
 */
const confirmDelivery=(id)=>{
    console.log(id);
    const url = store.state.baseUrl + "/order/confirmDelivery"
    const params = {
        orderId: id
    }
    const token = localGet('token')
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                showSuccessToast("确认成功");
                store.state.isShow = false;
                setTimeout(()=>{
                    store.state.isShow = true;
                },20);
            }else{
                showFailToast("确认失败");
            }
        }
    )
}
/**
 * 切换标签
 */
const changeTab=()=>{
    const url = store.state.baseUrl + "/order/queryOrders"
    const params = {
        type: activeName.value
    }
    const token = localGet('token')
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                orderList.value = res.data;
            }else{
                orderList.value = []
            }
        }
    )
}
onMounted(()=>{
    activeName.value = route.query.name
    const url = store.state.baseUrl + "/order/queryOrders"
    const params = {
        type: activeName.value
    }
    const token = localGet('token')
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                orderList.value = res.data;
            }else{
                showFailToast("暂无订单信息");
            }
        }
    )
})
</script>
