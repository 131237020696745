<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1.25rem 0">
    <!--标签栏-->
    <van-tabs v-model:active="active" @change="queryGoods" color="#d81e06" background="#f0f0f0" style="margin:0 0.5rem 0.8rem 0.5rem;">
        <van-tab title="综合排序"></van-tab>
        <van-tab title="销量排序"></van-tab>
        <van-tab title="积分排序"></van-tab>
    </van-tabs>
    <van-card
      v-for="(item,index) in goodsList"
      :key="index"
      style="border-radius: 7px;margin-left:0.5rem;margin-right:0.5rem;background-color:white"
    >
      <template #thumb>
        <van-image
          width="7rem"
          height="7.3rem"
          fit="cover"
          :src="item.photo"
          @click="toDetail(item.id)"
        />
      </template>
      <template #title>
        <div style="font-size:13px;font-weight:bold;" @click="toDetail(item.id)">{{ item.name }}</div>
      </template>
      <template #price>
        <div style="font-size:15px;margin-top:0.625rem;color:#d81e06">{{ item.needPoints }}积分</div>
      </template>
      <template #desc>
        <van-tag v-for="(tag,index2) in item.tags" style="margin:0.2rem 0.5rem 0.5rem 0"
        :key="index2" :type="tagTypeList[index2 % tagTypeList.length]">{{tag}}</van-tag>
      </template>
      <template #tags>
        <div style="font-size:0.6875rem;color:#707070">已兑换{{item.salesVolume}}</div>
      </template>
      <template #num>
        <van-button type="primary" size="mini" icon="star-o" @click="collect(item.id)">收藏</van-button>
      </template>
    </van-card>
</div>
<van-divider>没有更多了</van-divider>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { request } from '@/api/axiosFun';
import { showSuccessToast, showFailToast } from 'vant';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();

const active = ref(0);
const tagTypeList = ["success","danger","primary"]
const goodsList = ref([{
    name:"雀巢（Nestle）速溶咖啡 1+2 原味 微研磨 冲调饮品 盒装90条1350g 白敬亭同款",
    photo: "https://img10.360buyimg.com/n7/jfs/t1/214187/28/24729/115214/63b4d588F81ad4d1e/40d39ffadb01ea8d.jpg",
    needPoints: 100,
    tags: ["饮品","提神"],
    info: "商品名称：雀巢原味咖啡商品编号：1233203商品毛重：1.56kg商品产地：广东东莞/上海类别：三合一混咖啡口味：原味咖啡净含量：>1kg(L)烘焙程度：中度烘焙包装形式：盒装国产/进口：国产",
    salesVolume: "10"}]);

const init = ()=>{
    const url = store.state.baseUrl + "/goods/queryGoods";
    const params = {
      type: 0
    }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                goodsList.value = res.data
            }
        }
    )
}
init();

const queryGoods=()=>{
  const url = store.state.baseUrl + "/goods/queryGoods";
  const params = {
    type: active.value
  }
    request("post",url,params).then(
        (res)=>{
            if(res.success){
                goodsList.value = res.data
            }else{
                showFailToast(res.errorMsg);
            }
        }
    )
}


const collect=(goodsId)=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  const url = store.state.baseUrl + "/goodsCollect/collect"
  const params = {
    goodsId: goodsId
  }
  const token = localGet('token')
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
          showSuccessToast(res.data)
      }else{
          showFailToast(res.errorMsg)
      }
    }
  )
}

const toDetail=(id)=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  router.push({
    path: '/goodsDetail',
    query: {
        goodsId: id
    }
  })
}
</script>