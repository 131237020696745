<template>
<div style="background-color: #f0f0f0;padding: 0 0 1rem 0">
<!--轮播图-->
<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="margin-top:5px"> 
<van-swipe-item v-for="(item,index) in goods.pictures" :key="index">
    <van-image
    fit="cover"
    :src="item"
    width="100%"
    height="20rem"
    />
</van-swipe-item>
</van-swipe>
<!--商品信息展示-->
<van-cell-group inset style="margin:1rem 0.5rem 0.5rem 0.5rem;">
    <template #default>
        <div style="font-size:1rem;font-weight:bold;margin:0.5rem 1rem 0.5rem 1rem">{{ goods.name }}</div>
        <div style="margin-left:1rem;margin-bottom:0.5rem"> 
            <van-tag style="margin:0.3rem 0.5rem 0.5rem 0" size="medium"
            v-for="(item,index) in goods.tags" :key="index" :type="tagTypeList[index%tagTypeList.length]">{{ item }}</van-tag>
            <div style="font-size:1.2rem;font-weight:bold;color:#d81e06;float:right;margin-right:1.5rem">
            {{ goods.needPoints }}积分
            </div>
        </div>
        <van-divider />
        <div style="font-size:0.9rem;font-weight:bold;margin:0.5rem 1rem 0.5rem 1rem">商品详情</div>
        <div style="margin:0.5rem 1rem 0.5rem 1rem">
            {{ goods.info }}
        </div>
        <van-divider />
        <!--用户评价-->
        <van-row>
            <van-col span="8">
                <div style="font-size:0.9rem;font-weight:bold;margin:0.5rem 1rem 0.5rem 1rem">商品评价</div>
            </van-col>
            <van-col span="8"></van-col>
            <van-col span="8">
                <div style="margin:0.7rem 0rem 0.5rem 2.8rem;color:#e16531" @click="toGoodsComments(goods.id)">查看全部 ></div>
            </van-col>
        </van-row>
        <div style="margin:0.5rem 0rem 0.5rem 1rem" v-if="goodsComments.id != -1">
            <van-row>
                <van-col span="4">
                    <van-image
                    round
                    width="2.5rem"
                    height="2.5rem"
                    fit="cover"
                    :src="goodsComments.userAvatar"
                    />
                </van-col>
                <van-col span="15">
                    <van-row style="font-size:0.8rem">{{goodsComments.userName}}</van-row>
                    <van-row><van-rate v-model="goodsComments.stars" readonly allow-half size="1rem" style="margin-top:0.5rem"/></van-row>
                </van-col>
                <van-col span="5" style="margin-top:0.3rem">{{goodsComments.commentTime}}</van-col>
            </van-row>
            <div style="margin:0.7rem 0 0.2rem 0.2rem;font-size:0.8rem">
                {{goodsComments.comments}}
            </div>
            <div style="margin:0.7rem 0 0.2rem 0.2rem;">
                <van-image
                v-for="(photo,index2) in goodsComments.photoList"
                :key="index2"
                width="6rem"
                height="6rem"
                radius="5px"
                :src="photo"
                style="margin-right: 0.5rem"
                />
            </div>
        </div>
        <van-empty v-if="goodsComments.id == -1" description="暂无评价" />
        <van-divider />
    </template>
</van-cell-group>
</div>
<!--购买弹出层-->
<van-popup
  v-model:show="showBottom"
  position="bottom"
  :style="{ height: '50%' }"
>
    <template #default>
        <!--图片信息-->
        <van-row>
            <van-col span="10">
                <van-image
                width="8rem"
                height="10rem"
                fit="cover"
                position="left"
                radius="5px"
                :src="goods.photo"
                style="margin:1rem"
                />
            </van-col>
            <van-col span="13">
                <van-row>
                    <div style="font-size:0.9rem;font-weight:bold;margin:1rem 0 0 0.3rem">{{ goods.name }}</div>
                </van-row>
                <van-row>
                    <div style="font-size:1.1rem;font-weight:bold;color:#d81e06;margin:1rem 0 1rem 0.3rem"> {{ goods.needPoints }}积分</div>
                </van-row>
                <van-row>
                    <div style="font-size:0.8rem;font-weight:500;margin: 0 0 0 0.3rem">库存：{{ goods.stack }}</div>
                </van-row>
            </van-col>
        </van-row>
        <!--数量-->
        <div style="margin-top:1rem">
            <van-row>
                <van-col span="8">
                    <div style="font-size:0.95rem;font-weight:bold;margin-left:1rem">数量</div>
                </van-col>
                <van-col span="9"></van-col>
                <van-col span="7">
                    <van-stepper v-model="number" />
                </van-col>
            </van-row>
        </div>
        <!--购买按钮-->
        <div style="margin-top:2.4rem">
            <van-button type="primary" block @click="exchange">兑换</van-button>
        </div>
    </template>
</van-popup>

  <!--商品详情页的底边栏-->
<van-action-bar v-if="route.path == '/goodsDetail'">
    <van-action-bar-icon icon="chat-o" text="客服" color="#ee0a24" />
    <van-action-bar-icon icon="star" text="收藏" color="#ff5000" />
    <van-action-bar-button type="danger" text="立即购买" @click="showBuy()" />
</van-action-bar>
</template>

<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useRoute } from "vue-router"
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const route = useRoute();
const tagTypeList = ["success","danger","primary"]
const goods = ref({});
const value = ref(5);
const showBottom = ref(false);
const number = ref(0);
const goodsComments = ref({id:-1});


onMounted(()=>{
    const goodsId = route.query.goodsId
    console.log(route.path);
    //todo: 查询商品信息
    const url = store.state.baseUrl + "/goods/queryGoodsById";
    const params = {
        goodsId: goodsId
    }
    const token = localGet('token');
    request("post",url,params,token).then(
        (res)=>{
            if(res.success){
                goods.value = res.data;
            }else{
                showFailToast("暂无此商品")
            }
        }
    )
    const url2 = store.state.baseUrl + "/goodsComments/queryLatestComments"
    request("post",url2,params,token).then(
        (res)=>{
            if(res.success){
                goodsComments.value=res.data
            }
        }
    )
})

const toGoodsComments=(goodsId)=>{
    router.push({
        path: "/goodsComments",
        query: {
            goodsId: goodsId
        }
    })
}

/**
 * 购买方法
 */
const exchange = ()=>{
    const url1 = store.state.baseUrl + "/goods/checkBeforePurchase";
    const params = {
        goodsId: goods.value.id,
        number: number.value
    }
    const token = localGet('token');
    request("post",url1,params,token).then(
        (res)=>{
            if(res.success){
                //进行购买下单
                const url2 = store.state.baseUrl + "/goods/purchase";
                request("post",url2,params,token).then(
                    (res)=>{
                        if(res.success){
                            showSuccessToast("兑换成功");
                            showBottom.value = false;
                        }else{
                            showFailToast("兑换失败");
                        }
                    }
                )
            } else {
                showFailToast(res.errorMsg);
            }
        }
    )


}


const showBuy = ()=>{
    showBottom.value=true;
}
</script>

