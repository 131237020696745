<template>
<div style="background-color: #f0f0f0;">
  <!--轮播图-->
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="margin-top:5px"> 
    <van-swipe-item v-for="(item,index) in swipeImages" :key="index">
      <van-image
      fit="cover"
      :src="item"
      width="100%"
      height="12rem"
      />
    </van-swipe-item>
  </van-swipe>

  <!--导航-->
  <van-cell-group inset style="margin:0.5rem;">
    <template #default>
      <van-row >
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2rem" @click="toActivityJoin" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon1.png" />
          </van-row>
        </van-col>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2rem" @click="toPointsRecords" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon2.png" />
          </van-row>
        </van-col>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2rem" @click="toBlog" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon3.png" />
          </van-row>
        </van-col>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2.2rem" @click="toShop" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon4.png" />
          </van-row>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.4rem;font-size:0.7rem">参与详情</div></van-col>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.5rem;font-size:0.7rem">积分明细</div></van-col>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.5rem;font-size:0.7rem">博客分享</div></van-col>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.6rem;font-size:0.7rem">积分商城</div></van-col>
      </van-row>
      <!--第二排-->
      <van-row>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2rem" @click="toNews" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon5.png" />
          </van-row>
        </van-col>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2rem" @click="toAnswer" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon6.png" />
          </van-row>
        </van-col>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2.1rem" @click="toSort" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/排名.png" />
          </van-row>
        </van-col>
        <van-col span="4" offset="2">
          <van-row style="margin-top:1rem">
            <van-icon size="2.1rem" @click="toCarbonRecords" name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/记录.png" />
          </van-row>
        </van-col>
      </van-row>
      <van-row style="margin-bottom:1rem">
        <van-col span="5" offset="1" > <div style="margin:0.2rem 0 0 0.4rem;font-size:0.7rem">双碳新闻</div></van-col>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.5rem;font-size:0.7rem">知识答题</div></van-col>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.5rem;font-size:0.7rem">减碳排名</div></van-col>
        <van-col span="5" offset="1"> <div style="margin:0.2rem 0 0 0.6rem;font-size:0.7rem">减碳记录</div></van-col>
      </van-row>
      <!-- <van-grid square :border="false" :column-num="4">
        <van-grid-item v-for="(item,index) in gridList" :key="index" :icon="item.icon" :text="item.name" />
      </van-grid> -->
    </template>
  </van-cell-group>
  <!--标签栏-->
  <van-tabs v-model:active="active" @change="queryActivies" color="#d81e06" background="#f0f0f0" style="margin:0 0.5rem 0.3rem 0.5rem;">
    <van-tab title="最新活动"></van-tab>
    <van-tab title="热门活动"></van-tab>
    <van-tab title="积分排序"></van-tab>
    <van-tab title="未报满"></van-tab>
    
  </van-tabs>

  <!--活动瀑布流-->
  <div>
    <van-card
      v-for="(item,index) in activityList"
      :key="index"
      style="border-radius: 7px;margin-left:0.5rem;margin-right:0.5rem;background-color:white"
    >
      <template #thumb>
        <van-image
          width="7rem"
          height="7.3rem"
          fit="cover"
          :src="item.photo"
        />
      </template>
      <template #tags>
        <div style="margin-top:5px"><van-tag type="primary">时间：{{item.time}}</van-tag></div>
        <div style="margin-top:5px"><van-tag type="primary" color="#d4237a">地点：{{item.place}}</van-tag></div>  
        <div style="margin-top:5px"><van-tag type="success">减碳量：{{item.reduceCarbon}}kg</van-tag></div> 
      </template>
      <template #num>
        <van-button type="primary" color="#14b34e" size="small" style="border-radius: 10px" @click="confirm(item.name,item.details,item.id)">报名参加</van-button>
      </template>
      <template #title>
        <div style="font-size:15px;font-weight:bold;">{{ item.name }}</div>
      </template>
      <template #price>
        <div style="font-size:15px;margin-top:0.625rem;color:#0e932e">{{ item.points }}积分</div>
      </template>
      <template #tag>
        <van-tag type="primary" mark>{{item.enrollment}}/{{item.capacity}}</van-tag>
      </template>
    </van-card>
  </div>

  <!--确认报名弹出层-->
  <van-dialog v-model:show="show" title="是否报名该活动" :show-confirm-button="false">
    <template #default>
      <van-collapse v-model="activeNames">
      <van-collapse-item title="活动" name="1">
        {{ currentActivity }}
      </van-collapse-item>
      <van-collapse-item title="活动详情" name="2">
        {{ currentDetails }}
      </van-collapse-item>
    </van-collapse>
    <van-button type="danger" @click="show=false"  style="margin:1rem 0 1rem 4.5rem">取消</van-button>
    <van-button type="primary" @click="enrollActivity" style="margin:1rem 0 1rem 3.5rem">确认</van-button>
    </template>
  </van-dialog>
</div>
</template>

<script setup>
import { ref } from "vue";
import {request} from '@/api/axiosFun';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showDialog } from "vant";
import { showSuccessToast, showFailToast } from 'vant';
import { localGet } from '@/utils'

const swipeImages = [
    "https://p6-tt.byteimg.com/origin/pgc-image/c95b2727944b4130ae2f7d1655df06df?from=pc",
    "https://p1.itc.cn/q_70/images03/20210824/abc33472733a48c2ba88b813adf6c30a.png",
    "https://p4.itc.cn/q_70/images03/20210824/0376872bf71b4f6dad57031a8209a900.jpeg",
    "https://img0.baidu.com/it/u=555698145,3625129518&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=389",
    "https://p2.itc.cn/q_70/images03/20210612/e6af94e53e2d4e8c9318ae54148b1644.jpeg"
    ];
const gridList = [{icon: "https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon1.png",name: "参与详情"},
      {icon: "https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon2.png",name: "积分明细"},
      {icon: "https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon3.png",name: "博客分享"},
      {icon: "https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon4.png",name: "积分商城"},
      {icon: "https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon5.png",name: "双碳新闻"},
      {icon: "https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/icon6.png",name: "知识答题"}
]
const activityList = ref([])
const active = ref(0);
const store = useStore();
const router = useRouter();
const show = ref(false);
const activeNames = ref(['1','2']);
const currentActivity=ref('');
const currentDetails=ref('');
const currentActivityId=ref(0);

const init = ()=>{
  const baseUrl = store.state.baseUrl;
  const token = localGet('token');
  const params = {
    type: 0
  }
  const url = baseUrl+"/activity/queryActivities";
  request("post",url,params,token).then(
    (res)=>{
      activityList.value = res.data;
    }
  )
}
init();

const queryActivies=()=>{
  const baseUrl = store.state.baseUrl;
  const token = localGet('token');
  const params = {
    type: active.value
  }
  const url = baseUrl+"/activity/queryActivities";
  request("post",url,params,token).then(
    (res)=>{
      activityList.value = res.data;
    }
  )

}

/**
 * 展示报名确认弹出层
 */
const confirm=(name,details,id)=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  currentActivity.value=name;
  currentDetails.value=details;
  currentActivityId.value=id;
  show.value=true;
}

/**
 * 报名
 */
const enrollActivity=()=>{
  console.log(currentActivityId.value);
  const url = store.state.baseUrl + '/activity/enrollActivity'
  const params = {
    activityId: currentActivityId.value
  }
  const token = localGet('token')
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
        showSuccessToast("报名成功，可在参与活动处查看");
        show.value=false;
      }else{
        showFailToast(res.errorMsg);
        show.value=false;
      }
    }
  )
}

const toSort=()=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  router.push("/carbonsort");
}

const toCarbonRecords=()=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  router.push("/carbonRecords");
}

const toBlog=()=>{
  router.push("/blog");
}

const toShop=()=>{
  router.push("/shop");
}

const toActivityJoin = ()=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  router.push("/activityJoin");
}

const toPointsRecords = ()=>{
  if(localGet('token')==null){
    showFailToast("未登录，请先登录")
    router.push("/login");
    return;
  }
  router.push("/pointsRecords");
}

const toNews=()=>{
  router.push("/news")
}

const toAnswer=()=>{
  const url="https://ks.wjx.top/vm/wsnfvT1.aspx#";
  window.open(url);
}
</script>

<style>
:root:root{
  --van-card-thumb-size: 7.2rem
}
</style>

