<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1.5rem 0">
  <div style="font-size:1.15rem;font-weight:bold;color:#0e932e;margin:0rem 0rem 1rem 8.2rem">活动参与详情</div>
  <div >
    <van-card
      v-for="(item,index) in enrollActivityList"
      :key="index"
      style="border-radius: 7px;margin-left:0.5rem;margin-right:0.5rem;background-color:white;margin-top:1rem"
    >
      <template #thumb>
        <van-image
          width="7rem"
          height="7.3rem"
          fit="cover"
          :src="item.activity.photo"
        />
      </template>
      <template #tags>
        <div style="margin-top:5px"><van-tag type="primary">时间：{{item.activity.time}}</van-tag></div>
        <div style="margin-top:5px"><van-tag type="primary" color="#d4237a">地点：{{item.activity.place}}</van-tag></div>  
        <div style="margin-top:5px"><van-tag type="success">减碳量：{{item.activity.reduceCarbon}}kg</van-tag></div> 
      </template>
      <template #num>
        <van-button v-if="item.status!=2" type="danger" size="mini" style="border-radius: 10px" @click="showCancel(item.activity.name,item.activity.details,item.activityId)">取消报名</van-button>
        <van-button type="primary" size="mini" style="border-radius: 10px;margin:10px 0 0 10px" @click="showDaka(item.activity.name,item.activityId)">活动打卡</van-button>
      </template>
      <template #title>
        <div style="font-size:15px;font-weight:bold;">{{ item.activity.name }}</div>
      </template>
      <template #price>
        <div style="font-size:15px;margin-top:0.625rem;color:#0e932e">{{ item.activity.points }}积分</div>
      </template>
      <template #tag>
        <van-tag type="danger" mark v-if="item.status==0">未完成</van-tag>
        <van-tag type="primary" mark v-if="item.status==1">待审核</van-tag>
        <van-tag type="success" mark v-if="item.status==2">已完成</van-tag>
      </template>
    </van-card>
  </div>
</div>
<van-divider>没有更多了</van-divider>

<!--打卡弹出层-->
<van-dialog v-model:show="show1" title="活动打卡" :show-confirm-button="false">
  <template #default>
    <van-collapse v-model="activeNames">
      <van-collapse-item title="活动" name="1">
        {{ currentActivity }}
      </van-collapse-item>
      <van-collapse-item title="打卡上传" name="2">
        <template #default>
          <van-uploader v-model="fileList" multiple :max-count="5" :after-read="uploadImage"/>
        </template>
      </van-collapse-item>
    </van-collapse>
  <van-button type="danger" @click="show1=false"  style="margin:1rem 0 1rem 4.5rem">取消</van-button>
  <van-button type="primary" @click="submitProve" style="margin:1rem 0 1rem 3.5rem">确认</van-button>
  </template>
</van-dialog>
<!--确认取消报名弹出层-->
<van-dialog v-model:show="show2" title="请确认是否取消报名" :show-confirm-button="false">
  <template #default>
    <van-cell-group style="margin:1rem 0 1rem 0">
      <van-cell title="活动名" :value="currentActivity" />
      <van-cell title="活动详情" :value="currentDetails" />
    </van-cell-group>
    <van-button type="danger" @click="show2=false"  style="margin:1rem 0 1rem 4.5rem">取消</van-button>
    <van-button type="primary" @click="cancelEnroll" style="margin:1rem 0 1rem 3.5rem">确认</van-button>
  </template>
</van-dialog>
</template>

<script setup>
import { ref } from "vue";
import axios from 'axios';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const enrollActivityList = ref([]);
const show1 = ref(false);
const fileList = ref([]);
const prove = ref("");
const proveList = ref([])
const activeNames = ref(['1','2']);
const currentActivity=ref('');
const currentActivityId=ref(0);
const show2=ref(false);
const currentDetails =ref("");

const init = ()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
        return;
    }
    const token = localGet('token');
    const url = store.state.baseUrl + "/activity/queryActivityJoin"
    request("post",url,{},token).then(
        (res)=>{
            console.log(res)
            if(res.success){
                enrollActivityList.value = res.data;
            }else{
                showFailToast("暂未参加活动")
            }
        }
    )
}
init();
/**
 * 取消参与报名
 */
const cancelEnroll=()=>{
  const url = store.state.baseUrl + "/activity/cancelEnrollActivity";
  const token = localGet('token');
  const params = {
    activityId: currentActivityId.value
  }
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
        showSuccessToast("取消成功");
        show2.value=false;
        //todo: 刷新
        store.state.isShow = false;
        setTimeout(()=>{
            store.state.isShow = true;
        },20);
      }else{
        showFailToast("取消失败");
        show2.value=false;
      }
    }
  )
}

const showCancel=(name,details,id)=>{
  if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
        return;
    }
  show2.value=true;
  currentActivity.value=name;
  currentDetails.value=details;
  currentActivityId.value=id;
}

/**
 * 提交证明
 */
const submitProve=()=>{
  // console.log(proveList.value);
  const url =store.state.baseUrl +"/activitySubmit/submitActivityProve";
  const token =localGet('token');
  let i;
  for(i=0;i<proveList.value.length;i++){
    if(i==0){
      prove.value=proveList.value[i];
    }else{
      prove.value+=","+proveList.value[i];
    }
  }
  // console.log(prove.value);
  const params = {
    activityId: currentActivityId.value,
    prove: prove.value
  }
  request("post",url,params,token).then(
    (res)=>{
      if(res.success){
          showSuccessToast("打卡上传成功");
          show1.value=false;
          //todo: 刷新页面;
          store.state.isShow = false;
          setTimeout(()=>{
              store.state.isShow = true;
          },20);
      }else if(res.errorMsg == "已打卡,不可重复提交同一活动"){
          showFailToast("已打卡,不可重复提交同一活动");
          show1.value=false;
      }else{
          showFailToast("上传失败");
          show1.value=false;
      }

    }
  )
}

const showDaka = (activityName,activityId)=>{
  if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
        return;
    }
  show1.value=true;
  currentActivity.value=activityName;
  currentActivityId.value=activityId;
}

const uploadImage=(fileData)=>{
  // 此时可以自行将文件上传至服务器
  const baseUrl = store.state.baseUrl;
  const dataV = new FormData(); //重点--使用formdata添加需要一起传的参数
  dataV.append("file", fileData.file);
    axios({
            method: "post",
            headers: { "Content-Type": "multipart/form-data" }, //重点--请求头
            url: baseUrl+"/file/upload",
            data: dataV,
        }).then(function(response){
            console.log(response.data);
            proveList.value.push(response.data.data); 
        });
}

</script>

<style>
</style>