<template>
<div style="background-color: #f0f0f0;padding: 1rem 0 1.25rem 0">
    <van-row>
        <van-col span="7" offset="1">
            <van-row style="margin-top:1.8rem">
                <van-image
                round
                width="5.5rem"
                height="5.5rem"
                fit="cover"
                :src="userList[1].avatar"
                />
            </van-row>
        </van-col>
        <van-col span="8">
            <van-row>
                <van-image
                round
                width="7.5rem"
                height="7.5rem"
                fit="cover"
                :src="userList[0].avatar"
                />
            </van-row>
        </van-col>
        <van-col span="7" offset="1">
            <van-row style="margin-top:2rem">
                <van-image
                round
                width="5rem"
                height="5rem"
                fit="cover"
                :src="userList[2].avatar"
                />
            </van-row>
        </van-col>
    </van-row>
    <van-row>
        <van-col span="8">
            <van-row style="margin:0.5rem 0 0 1rem">
                <van-icon name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/奖杯1-2.png" size="1.5rem"/>
                <span style="margin:0.4rem 0 0 0;font-size:0.7rem;font-family:Microsoft YaHei">{{ userList[1].name }}</span>
            </van-row>
            <van-row>
                <span style="margin:0.3rem 0 0 1rem;color:#0e932e;font-size:0.8rem;font-weight:bold;font-family:Microsoft YaHei">减碳量：{{ userList[1].reduceCarbon }}kg</span>
            </van-row>
        </van-col>
        <van-col span="8">
            <van-row style="margin:0.5rem 0 0 0rem">
                <van-icon name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/奖杯2-1.png" size="1.8rem"/>
                <span style="margin:0.4rem 0 0 0;font-size:0.7rem;font-family:Microsoft YaHei">{{ userList[0].name }}</span>
            </van-row>
            <van-row>
                <span style="margin:0.2rem 0 0 1rem;color:#0e932e;font-size:0.8rem;font-weight:bold;font-family:Microsoft YaHei">减碳量：{{ userList[0].reduceCarbon }}kg</span>
            </van-row>
        </van-col>
        <van-col span="8">
            <van-row style="margin:0.5rem 0 0 0.5rem">
                <van-icon name="https://my-picture-1312810698.cos.ap-shanghai.myqcloud.com/picture/奖杯2-3.png" size="1.5rem"/>
                <span style="margin:0.4rem 0 0 0;font-size:0.7rem;font-family:Microsoft YaHei">{{ userList[2].name }}</span>
            </van-row>
            <van-row>
                <span style="margin:0.3rem 0 0 1rem;color:#0e932e;font-size:0.8rem;font-weight:bold;font-family:Microsoft YaHei">减碳量：{{ userList[2].reduceCarbon }}kg</span>
            </van-row>
        </van-col>
    </van-row>
    <van-cell-group inset v-for="(item,index) in userList" :key="index" style="margin:1rem 0.5rem 0.5rem 0.5rem">
        <van-cell size="large" title-style="font-size:1rem;font-weight:bold;" center>
            <template #title>
                <van-row>
                <van-col span="8">
                    <van-image
                    round
                    width="2.9rem"
                    height="2.9rem"
                    fit="cover"
                    :src="item.avatar"
                    />
                </van-col>
                <van-col span="15" style="margin-left:0.3rem">
                    <van-row style="font-size:0.8rem;color:#d81e06;font-weight:blod;font-family:Microsoft YaHei">No.{{index+1}}</van-row>
                    <van-row style="font-size:0.75rem;font-weight:blod;font-family:Microsoft YaHei">{{item.name}}</van-row>
                    <van-row style="font-size:0.6rem;color:#707070;font-family:Microsoft YaHei;">{{ item.school }}</van-row>
                </van-col>
            </van-row>
            </template>
            <template #value>
                <div  style="font-size:1.2rem;font-weight:bold;color:#0e932e"> -{{ item.reduceCarbon }}kg</div>
            </template>
        </van-cell>
    </van-cell-group>
</div>
<van-divider>没有更多了</van-divider>
</template>
<script setup>
import { ref } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { showSuccessToast, showFailToast } from 'vant';
import { request } from '@/api/axiosFun';
import { localGet } from '@/utils'

const store = useStore();
const router = useRouter();
const userList = ref([]);
const init = ()=>{
    if(localGet('token')==null){
        showFailToast("未登录，请先登录")
        router.push("/login");
        return;
    }
    const url = store.state.baseUrl + "/user/queryCarbonSort";
    request("post",url,{}).then(
        (res)=>{
            console.log(res)
            userList.value=res.data;
        }
    )
}
init();
</script>